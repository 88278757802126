(() => {
    const pageType = document.getElementById('page-type');

    if (!pageType) {
        return;
    }
    const StartPageInteractions = () => {
        const slideLinks = Array.from(document.querySelectorAll('.swiper-container .slide__inner a'));
        slideLinks.forEach(x => {
            x.addEventListener('click', () => {
                window.dataLayer.push({
                    'bannerTitle': x.textContent.trim(),
                    'event': 'interactionsBanners'
                });
            });
        });
    };
    const ArticlePageInteractions = () => {
        const teaserContainers = Array.from(document.querySelectorAll('.teasers-container'));
        teaserContainers.forEach(container => {
            Array.from(container.querySelectorAll('.teaser__title a')).forEach(link => {
                link.addEventListener('click', () => {
                    window.dataLayer.push({
                        'postTitle': link.textContent.trim(),
                        'event': 'interactionsRelatedPost'
                    });
                })
            })
        });
    };
    const CourseDetailsPageInteractions = () => {
        const bookButtons = [
            document.querySelector('.basic-info__card > .button'),
            document.querySelector('.book-course-container .container > .button')
        ];
        const name = String(document.querySelector('.heading .heading__title').textContent).trim()
        bookButtons.forEach(x => x && x.addEventListener('click', () => {
            window.dataLayer.push({
                'productName': name,
                'event': 'coursesAndCertificationsBookingStepOne'
            });
        }))
    };
    const CourseBookingPageInteractions = () => {
        const bookingForm = document.getElementById('bookingForm')
        const name = String(document.querySelector('.heading .heading__title').textContent).trim()
        let errorMessages = [];
        const loadErrorMessages = () => {
            errorMessages = Array.from(document.querySelectorAll('[data-error-message-for]'))
                .map(x => String(x.textContent))
                .filter(x => x && x.trim());
        }

        const pushForBookingError = (productName, formError) => {
            window.dataLayer.push({
                'productName': productName,
                'formError': formError,
                'event': 'coursesAndCertificationsBookingFailed'
            });
        }
        loadErrorMessages();
        if (errorMessages.length > 0) {
            //if backend returned some other errors after booking form
            pushForBookingError(name, errorMessages[0]);
        }
        bookingForm.onSubmitFail = () => {
            loadErrorMessages();
            pushForBookingError(name, errorMessages[0]);
        }
    };
    const CourseBookedInteractions = () => {
        const productName = document.getElementById('productName').value;
        const productCategory = document.getElementById('productCategory').value;
        const paymentMethod = document.getElementById('paymentMethod').value;
        const transactionId = document.getElementById('transactionId').value;
        const transactionTotalInput = document.getElementById('transactionTotal');
        const courseCode = document.getElementById('courseCode').value;
        const transactionTotal = transactionTotalInput ? parseFloat(String(transactionTotalInput.value).replace(',', '.')) : null
        window.dataLayer.push({
            'currency': 'SEK',
            'value': transactionTotal,
            'payment_type': paymentMethod,
            'item': {
                'item_name': productName
            },
            'event': 'add_payment_info'
        });
        window.dataLayer.push({
            'event': 'purchase',
            'currency': 'SEK',
            'transaction_id': transactionId,
            'value': transactionTotal,
            'item': [
                {
                    'item_id': courseCode,
                    'item_name': productName,
                    'item_category': productCategory,
                    'price': transactionTotal,
                    'quantity': 1
                }
            ]
        })
    };

    const pageTypeValue = String(pageType.value);
    switch (pageTypeValue) {
        case 'StartPage':
            StartPageInteractions();
            break;
        case 'ArticlePage':
            ArticlePageInteractions();
            break;
        case 'CourseDetailsPage':
            CourseDetailsPageInteractions();
            break;
        case 'CourseBookingPage':
            CourseBookingPageInteractions();
            break;
        case 'CourseBooked':
            CourseBookedInteractions();
            break;
        case 'CertificateDetailsPage':
            CourseDetailsPageInteractions();
            ArticlePageInteractions();
            break;
        case 'CertificateBookingPage':
            CourseBookingPageInteractions();
            break;
        case 'CertificateBooked':
            CourseBookedInteractions();
            break;
    }

    const newsletter = document.getElementById('newsletter-form');
    if (newsletter) {
        newsletter.onUserAddedSuccessfully = () => {
            window.dataLayer.push({
                'event': 'interactionsNewsletterSignUp'
            });
        };
    }

    const pushBlockLinks = Array.from(document.querySelectorAll('.fullwidth-push .fullwidth-push__content > a'));
    const ctaButtons = Array.from(document.querySelectorAll('.cta-button > a'));
    pushBlockLinks.concat(...ctaButtons).forEach(x => {
        x.addEventListener('click', () => {
            const onlyText = x.textContent.trim();
            window.dataLayer.push({
                'ctaButtonName': onlyText,
                'event': 'interactionsCallToActionButton'
            });
        });
    });
})();