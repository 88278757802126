if (window.matchMedia("(min-width: 992px)").matches) {
    
    var parallaxContainer = document.querySelector(".parallax-container");
    var parallaxElements = Array.from(document.querySelectorAll(".parallax__element"));
    var viewportHeight = document.documentElement.clientHeight;
    
    var config = {
        threshold: 0
    };
    
    if (parallaxContainer) {
        if ("IntersectionObserver" in window) {
            var observer = new IntersectionObserver(onIntersection, config);
            observer.observe(parallaxContainer);
        };
    };
    
    function onIntersection(entries) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                document.addEventListener("scroll", parallaxOnScroll, true);
            } else {
                document.removeEventListener("scroll", parallaxOnScroll, true);
            };
        });
    };
    
    var parallaxOnScroll = function() {
        var parallaxContianerRect = parallaxContainer.getBoundingClientRect().top;
        var parallaxElementsPositionLayerOne = ((viewportHeight - parallaxContianerRect) / 10) - 40;
    
        parallaxElements.forEach(function(elem) {
            elem.style.transform = "translateY(" + parallaxElementsPositionLayerOne + "px)";
        });
    };
}
