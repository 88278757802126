const pageType = document.getElementById('page-type');

if(pageType && (pageType.value === 'CourseDetailsPage' || pageType.value === 'CertificateDetailsPage')){
    const informationBlocks = document.querySelectorAll('.additional-info .information-block');
    for (let index = 0; index < informationBlocks.length; index++) {
        const informationBlock = informationBlocks[index];
        informationBlock.classList.add(`content-${index + 1}`);
    }

    const detailsInformationChevron = document.querySelector('.detail-information__header__chevron');
    const detailsInformationButton = document.querySelector('#detail-information__header__text');
    detailsInformationButton && (detailsInformationButton.onclick = function toggleDetailInformation() {
        const detailContentHiddenClass = "detail-information__content--hidden";
        const detailButtonClosedModifier = 'detail-information__header__chevron--state-closed';
        const detailContent = document.querySelector('#detail-information__content');

        window.toggleElementHeight(detailContent, detailContentHiddenClass);
        detailsInformationChevron.classList.toggle(detailButtonClosedModifier);
    });

    const basicInfoCardChevron = document.querySelector('.basic-info__card__chevron');
    const basicInfoCardButton = document.querySelector('#basic-info__card__cell--type-chevron');
    basicInfoCardButton && (basicInfoCardButton.onclick = function toggleBasicInfoCardInformation() {
        const basicInfoCardContentHiddenClass = "basic-info__card__cell--hidden";
        const basicInfoCardButtonClosedModifier = 'basic-info__card__chevron--state-inactive';
        const basicInfoCardContent = document.querySelector('#basic-info__card__content');

        window.toggleElementHeight(basicInfoCardContent, basicInfoCardContentHiddenClass);
        basicInfoCardChevron.classList.toggle(basicInfoCardButtonClosedModifier);
    });
}