import './js/common/expandCollapseAnimationHandler';
import './js/common/tootlip';
import './js/main.js';
import './scss/index.scss';
import './js/teasersAnimation';
import './js/teasersParallax';
import './js/topMenu';
import './js/coursedetailspage';
import './js/coursebookingpage';
import './js/certificatebookingpage';
import './js/bookingpages';
import './js/newsletter';
import './js/courseCardTitleShrinker';
import './js/contentContainerTitleShrinker';
import './js/common/GTMInteractions';
import './js/videoPlayer';

if (module.hot) module.hot.accept();
