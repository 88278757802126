import { getHiddenElementHeight } from './common/DOM';

//Mobile menu open
const mobileMenu = document.querySelector('.header');
document.getElementById('toggle-menu').addEventListener('click', () =>  mobileMenu.classList.toggle('is-open'));

//Search
const searchboxClickAwayListener = (e) => {
    if (e.target === searchButton || e.target.closest('.search-box')) return;
    searchbox.classList.remove('is-open');
    document.removeEventListener('click', searchboxClickAwayListener, true);
};
const searchbox = document.querySelector('.search-box');


if(searchbox) {
    const searchboxForm = searchbox.querySelector('input');
    const searchButton = document.getElementById('search-button');

    searchButton && searchButton.addEventListener('click', () => {
        if (searchbox.classList.contains('always-open')) {
            searchboxForm.focus();
            return;
        }
        else if (!searchbox.classList.contains('is-open')) {
            mobileMenu.classList.remove('is-open');
            searchboxForm.focus();
            document.addEventListener('click', searchboxClickAwayListener, true);
        }
        searchbox.classList.toggle('is-open');
    });
}

//Menu
const menuOpenButtons = Array.from(document.querySelectorAll('.header__main-menu .chevron'));
const menuLinks = Array.from(document.querySelectorAll('.header__main-menu li'));

const init = function() {
    const ipadsMatchMedia = 'screen and (orientation: portrait) and (min-device-width: 768px) and(max-device-width: 1366px) and(-webkit-min-device-pixel-ratio: 1.5)';

    menuLinks.forEach(link => {
        if (link.lastElementChild.tagName === 'DIV') {
            const listToOpen = link.querySelector('.list-container');
            link.addEventListener('mouseenter', () => {
                if (window.matchMedia('(min-width: 992px)').matches && !window.matchMedia(ipadsMatchMedia).matches) {
                    openDesktopMenu(listToOpen);
                }
            });

            link.addEventListener('mouseleave', () => {
                if (window.matchMedia('(min-width: 992px)').matches && !window.matchMedia(ipadsMatchMedia).matches) {
                    closeDesktopMenu(listToOpen);
                }
            });
        };
    });

    menuOpenButtons.forEach(button => button.addEventListener('click', () => {
        if (window.matchMedia('(max-width: 992px)').matches || window.matchMedia(ipadsMatchMedia).matches) {
            const listToOpen = button.closest('li').querySelector('.list-container');
            button.classList.toggle('is-open');
            toggleMobileMenu(listToOpen);
        }
    }));
};

function toggleMobileMenu(elem) {
    if (elem.classList.contains('is-visible')) {
        closeMobileMenu(elem);
        return;
    };
    openMobileMenu(elem);
}

function openMobileMenu(elem) {
    const elemHeight = getHiddenElementHeight(elem);

    addHeightAndVisibileClass(elem, elemHeight);

    elem.addEventListener('transitionend', () => {
        elem.style.height = null;
    });
}

function closeMobileMenu(elem) {
    elem.style.height = elem.scrollHeight + 'px';

    removeHeightAndVisibleClass(elem);
};

function openDesktopMenu(elem) {
    const elemHeight = getHiddenElementHeight(elem);
    const elemParentListContainer = elem.parentNode.closest('.list-container');
    let height;
    
    if(elemParentListContainer) {
        const parentListContainerHeight = elemParentListContainer.clientHeight;
        
        if (elemHeight > parentListContainerHeight) {
            const parentList = elemParentListContainer.querySelector('.submenu');
            addFakeMenuItems(parentList, elemHeight);
            elemParentListContainer.style.height = elemHeight + 'px';
            height = elemHeight;
        } else {
            height = parentListContainerHeight;
        }
    } else {
        height = elemHeight;
    };
    
    addHeightAndVisibileClass(elem, height);
};

function closeDesktopMenu(elem) {
    elem.style.height = elem.scrollHeight + 'px';
    const elemParentListContainer = elem.parentNode.closest('.list-container');
    
    if(elemParentListContainer) {
        const parentInnerList = elemParentListContainer.querySelector('.submenu');
        removeFakeMenuItems(parentInnerList);
        elem.style.height = elem.scrollHeight + 'px';
        parentInnerList.style.minHeight = 'auto';
        const parentInnerListHeight = parentInnerList.scrollHeight;
        parentInnerList.style.minHeight = '100%';
        elemParentListContainer.style.height = parentInnerListHeight + 'px';
    }
    
    removeHeightAndVisibleClass(elem);
};

function addFakeMenuItems(list, height) {
    const listHeight = list.scrollHeight;
    const listElemHeight = list.querySelector('li').scrollHeight;
    const elemsNumber = Math.round((height - listHeight) / listElemHeight);
    for (let i = 0; i < elemsNumber; i++) {
        const fragment = document.createDocumentFragment();
        const listElemClone = list.querySelector('li').cloneNode();
        const emptyDiv = document.createElement('div');
        emptyDiv.style.height = listElemHeight + 'px';
        listElemClone.classList.add('fake-list-item')
        listElemClone.appendChild(emptyDiv);
        fragment.appendChild(listElemClone);
        list.appendChild(fragment);
    };
};

function removeFakeMenuItems(list) {
    const listElems = Array.from(list.querySelectorAll('li.submenu__level-2__item'));
    listElems.forEach(elem => {
        if (elem.childNodes.length === 1) {
            elem.parentNode.removeChild(elem);
        };
    });
};

function addHeightAndVisibileClass(elem, elemHeight) {
    elem.classList.add('is-visible');
    elem.style.height = elemHeight + 'px';
}

function removeHeightAndVisibleClass(elem) {
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            elem.style.height = null;
        });
    });

    elem.classList.remove('is-visible');
}

init();