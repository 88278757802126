import Swiper from 'swiper';
import $ from 'jquery';

//Slider
const startpageSlider = new Swiper('.swiper-container', {
    direction: 'horizontal',
    simulateTouch: false,
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    preloadImages: false,
    lazy: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        //Progress bar speed animation is set in css
        type: "custom",
        renderCustom: function (swiper, current, total) {
            let html = "";
            for (var i = 1; i <= total; i++) {
                let className = swiper.params.pagination.bulletClass;
                if (i === current) {
                    className = swiper.params.pagination.bulletActiveClass;
                }

                let title = "";
                if (!window.pageIsInEditMode) {
                    title = swiper.slides[i].dataset.title;
                }
                else {
                    const firstChild = swiper.slides[i].firstChild;
                    if (firstChild
                        && firstChild.classList.contains("swiper-slide")
                        && firstChild.dataset
                        && firstChild.dataset.title) {
                        title = firstChild.dataset.title;
                    }
                }

                html += '<div class="swiper-bullet ' + className + '"><span class="progressbar"></span><span class="text--small hide-sm">' + title + '</span></div>';
            }
            return html;
        }
    },
    navigation: {
        nextEl: '.button--next',
        prevEl: '.button--prev'
    },
    autoplay: {
        delay: 10000
    }
});

const updateSliderTheme = function () {
    if (!startpageSlider.slides) {
        return;
    }

    const activeSlide = startpageSlider.slides[startpageSlider.activeIndex];

    const theme = $(activeSlide).data('theme');
    if (theme) {
        $(startpageSlider.$el).addClass('dark-theme');
    } else {
        $(startpageSlider.$el).removeClass('dark-theme');
    }
};

startpageSlider.on("slideChange", updateSliderTheme);
updateSliderTheme();

const cookieAccepted = localStorage.getItem('USER_COOKIE-ALERT-ACCEPTED');
if (!cookieAccepted) {
    const cookieElement = document.getElementById('cookieAlert');
    const button = document.getElementById('cookieAlertAcceptButton')
    button && button.addEventListener('click', () => {
        localStorage.setItem('USER_COOKIE-ALERT-ACCEPTED', true);
        cookieElement.classList.add('hide');
    });
    cookieElement && cookieElement.classList.remove('hide');
}

window.fitInputToContent = function fitInputToContent(element) {
    if (!element) {
        return;
    }

    if(element.value.length) {
        const container = document.createElement('div');
        container.classList.add('general-search-mock-container');
        const span = document.createElement('span');
        span.classList.add('general-search-mock');
        span.style.fontSize = window.getComputedStyle(element).fontSize;
        span.innerText = element.value;
        
        container.appendChild(span);
        document.body.appendChild(container);
        element.style.width = span.scrollWidth + 5 + 'px';
        document.body.removeChild(container);
    } else {
        element.style.width = null;
    }
}

const generalSearch = document.getElementById('general-search');
generalSearch && (generalSearch.oninput = function onGeneralSearchInput() {
    window.fitInputToContent(generalSearch);
});

window.desktopLink = function desktopLink(url) {
    if(this.screen.width > 767.98) {
        window.location.href = url;
    }
}

setTimeout(() => {
    if (generalSearch && generalSearch.value.length > 0) {
        window.fitInputToContent(generalSearch);
    }
}, 10);


const globalSearchChecbox = document.getElementById('global-search');
const courseSearchChecbox = document.getElementById('course-search');
const courseSearchButton = document.getElementById('course-search-button');
const globalSearchButton = document.getElementById('global-search-button');

if (generalSearch && globalSearchChecbox && courseSearchChecbox && courseSearchButton && globalSearchButton) {

    if (globalSearchChecbox.getAttribute('page-type') === 'search' && courseSearchChecbox.getAttribute('page-type') === 'search') {
        globalSearchButton.addEventListener('click', (event) => {
            event.preventDefault();

            globalSearchChecbox.checked = true;
            courseSearchChecbox.checked = false;

            redirectSearch('global');
        });

        courseSearchButton.addEventListener('click', (event) => {
            event.preventDefault();

            globalSearchChecbox.checked = false;
            courseSearchChecbox.checked = true;

            redirectSearch('course');
        });
    } else {
        globalSearchButton.addEventListener('click', (event) => {
            event.preventDefault();

            if (courseSearchButton.classList.contains('button--active')) {
                courseSearchButton.classList.remove('button--active');
            }

            if (!globalSearchButton.classList.contains('button--active')) {
                globalSearchButton.classList.add('button--active');
            }
            globalSearchChecbox.checked = true;
            courseSearchChecbox.checked = false;
        });

        courseSearchButton.addEventListener('click', (event) => {
            event.preventDefault();

            if (globalSearchButton.classList.contains('button--active')) {
                globalSearchButton.classList.remove('button--active');
            }

            if (!courseSearchButton.classList.contains('button--active')) {
                courseSearchButton.classList.add('button--active');
            }

            globalSearchChecbox.checked = false;
            courseSearchChecbox.checked = true;
        });
    }

    function redirectSearch(searchType) {
        let url = new URL(document.location);
        let params = new URLSearchParams(url.searchParams);
        let phrase = generalSearch.value;

        params.set('searchType', searchType);
        params.set('phrase', phrase);

        if (params.has('page') === true) {
            params.delete('page');
        }

        document.location.search = params.toString();
    }
}