import Validator from './common/formValidator';

if (document.getElementById('page-type') && document.getElementById('page-type').value === 'CourseBookingPage') {
  const liveClassFilter = document.getElementById('liveclassfilter');
  const startGuaranteeFilter = document.getElementById('startGuaranteefilter');
  const addNewParticipantButton = document.getElementById('add-new-participant');
  const removeParticipantButtons = Array.from(document.getElementsByClassName('remove-participant'));
  const bookingForm = document.getElementById('bookingForm');

  let validator = bookingForm ? new Validator('bookingForm', true) : undefined;

  if (validator) {
    validator.initialize();
  }

  const runFilters = () => {
    const buttons = document.querySelectorAll(`.dates .radiobutton`);

    for (const btn of buttons) {
      if (startGuaranteeFilter.checked && !btn.hasAttribute('data-startguarantee')) {
        if (!btn.classList.contains('radiobutton--hidden')) {
          btn.classList.add('radiobutton--hidden');
        }
      } else if (liveClassFilter && liveClassFilter.checked && btn.hasAttribute('data-liveclass')) {
        if (!btn.classList.contains('radiobutton--hidden')) {
          btn.classList.add('radiobutton--hidden');
        }
      } else if (btn.classList.contains('radiobutton--hidden')) {
        btn.classList.remove('radiobutton--hidden');
      }
    }

    if (validator) {
      validator.validateForm();
    }
  };

  const clearParticipantBox = participantBox => {
    const inputs = Array.from(participantBox.querySelectorAll('.input__input'));
    inputs.forEach(input => {
      input.value = null;
    });
  };

  const setParticipantBoxIndex = (participantBox, index) => {
    const indexInputs = Array.from(participantBox.querySelectorAll('input[type=hidden]'));
    const previousIndex = indexInputs[0].value;
    indexInputs.forEach(indexInput => {
      indexInput.value = index;
    });

    const inputs = Array.from(participantBox.querySelectorAll('.input__input'));
    inputs.forEach(input => {
      input.name = input.name.replace(`[${previousIndex}]`, `[${index}]`);
    });

    const errorBoxes = Array.from(participantBox.querySelectorAll('.error-message'));
    errorBoxes.forEach(errorBox => {
      errorBox.setAttribute('data-for', errorBox.getAttribute('data-for').replace(`[${previousIndex}]`, `[${index}]`));
    });
  };

  const addEventToRemoveButton = participantBox => {
    const removeButton = participantBox.querySelector('.remove-participant');
    removeButton.onclick = removeParticipant;
  };

  const addNewParticipant = () => {
    const participantsConatiner = document.querySelector('.participants');
    const participantBoxes = Array.from(document.getElementsByClassName('participant'));

    if (!participantsConatiner || !participantBoxes || participantBoxes.length < 1) {
      return;
    }

    const hiddenRemoveButton = participantsConatiner.querySelector('.input-wrapper--hidden');
    if (hiddenRemoveButton) {
      hiddenRemoveButton.classList.remove('input-wrapper--hidden');
    }

    const highestIndexValue = Math.max(...participantBoxes.map(x => x.querySelector('input[type=hidden]').value));
    const clonedParticipant = participantBoxes[0].cloneNode(true);
    clearParticipantBox(clonedParticipant);
    setParticipantBoxIndex(clonedParticipant, highestIndexValue + 1);
    addEventToRemoveButton(clonedParticipant);

    participantsConatiner.appendChild(clonedParticipant);

    if (validator) {
      validator = new Validator('bookingForm', true);
      validator.initialize();
      validator.validateForm();
    }
  };

  const removeParticipant = e => {
    const index = e.target.querySelector('input[type=hidden]').value;
    const participantBoxes = Array.from(document.getElementsByClassName('participant'));
    const participantsCount = participantBoxes.length;
    if (participantsCount === 1) {
      return;
    }

    const participantBoxToRemove = participantBoxes.find(x => x.querySelector('input[type=hidden]').value === index);
    participantBoxToRemove.parentNode.removeChild(participantBoxToRemove);

    if (participantsCount === 2) {
      const lastRemoveParticipantButton = document.querySelector('.remove-participant');
      lastRemoveParticipantButton.parentElement.classList.add('input-wrapper--hidden');
    }

    if (validator) {
      validator = new Validator('bookingForm', true);
      validator.initialize();
      validator.validateForm();
    }
  };

  liveClassFilter && (liveClassFilter.onchange = runFilters);
  startGuaranteeFilter && (startGuaranteeFilter.onchange = runFilters);
  addNewParticipantButton && (addNewParticipantButton.onclick = addNewParticipant);
  removeParticipantButtons &&
    removeParticipantButtons.forEach(removeParticipantButton => {
      removeParticipantButton.onclick = removeParticipant;
    });

  runFilters();
}
