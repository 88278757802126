import validatorsToBind from './validators';
'use strict';

class Validator {
  constructor(formId, validateInputOnFocusOut = false) {
    if (!formId) {
      throw 'Validator cannot be initialized without formid.';
    }
    this._formId = formId;
    this._validateInputOnFocusOut = validateInputOnFocusOut;
    this._form = document.getElementById(formId);
    if(!this._form) {
        throw `Cannot find form with id ${formId}`;
    }
    this._form.addEventListener('submit', (e) => {
      if (!this.validateInputsAndAddMessageIfInvalid()) {
        e.preventDefault();
        e.stopPropagation();
        this._form.onSubmitFail && this._form.onSubmitFail();
      }
      this.mapCheckboxesValue();
    });
  }

  mapCheckboxesValue() {
    this._inputs.filter(x => x.type === 'checkbox').forEach(input => input.value = (input.checked ? true : false));
  }


  findValidatorFor(type) {
    if(!type) {
      throw `Input requires data-type attribute.`;
    }
    let validator = validatorsToBind[type];
    if(!validator) {
        throw `Cannot find any validator for ${type}.`;
    }
    return validator;
  }

  initialize() {
    let inputs = Array.from(this._form.querySelectorAll('input[data-required]'));
    if(this._inputs && this._inputs.length > 0) {
      inputs = inputs.filter(x => x !== this._inputs.find(inp => inp === x));
    }

    for (let input of inputs) {
      input.validateValue = this.findValidatorFor(input.getAttribute('data-type')).bind(input);
      input.validateWithEventualErrors = function() {
        const errorForInput = this.form.querySelector(`.error-message[data-for='${this.name}']`);
        const parent = this.parentElement;
        let validationResult = this.validateValue();
        let errorMessagesForInput = this.form.querySelectorAll(`div[data-error-message-for='${this.name}']`);

        if (errorMessagesForInput && errorMessagesForInput.length > 0) {
          for (let error of errorMessagesForInput) {
            error.remove();
          }
        }

        if (validationResult.isCorrect) {
          if (parent.classList.contains('input--error')) {
            parent.classList.remove('input--error');
          }
        } else {
          if (!parent.classList.contains('input--error')) {
            parent.classList.add('input--error');
          }

          if(errorForInput) {
            const div = document.createElement('div');
            div.setAttribute('data-error-message-for', this.name);
            const textNode = document.createTextNode(validationResult.message);
            div.appendChild(textNode);
            errorForInput.appendChild(div);
          }
        }

        return validationResult.isCorrect;
      }.bind(input);
      if(this._validateInputOnFocusOut) {
        input.addEventListener('focusout', (e) => {
          input.validateWithEventualErrors();
        });
      }
      input.addEventListener('change',(e) => {
        input.validateWithEventualErrors();
        this.validateForm();
      });
    }
    this._inputs = inputs;
  }

  validateInputsAndAddMessageIfInvalid() {
    let formValid = true;
    for (let input of this._inputs) {
      if (!input.validateWithEventualErrors()) {
        formValid = false;
      }
    }
    return formValid;
  }

  validateForm() {
    let valid = true;
    for (var input of this._inputs) {
      if (!input.validateValue().isCorrect) {
        valid = false;
      }
    }
    return valid;
  }

  validateAndEnableSubmitButton() {
    const valid = this.validateForm();

    const submitButton = this._form.querySelector(`button[type='submit']`);

    if (valid) {

      if (submitButton.getAttribute('disabled'))
        submitButton.removeAttribute('disabled');
      
    } else {

      if (!submitButton.getAttribute('disabled'))
        submitButton.setAttribute('disabled', true);

    }
  }
}

export default Validator;
