const pageType = document.getElementById('page-type');

if(pageType && (pageType.value === 'CourseBookingPage' || pageType.value === 'CertificateBookingPage')){
    const form = document.getElementById('bookingForm');
    const cancelButton = document.getElementById("cancel");
    cancelButton && (cancelButton.onclick = function(e) {
        e.preventDefault();
        const modal = document.getElementById('cancelmodal');
        form.classList.add('form--blured');
        modal.classList.add('cancel-modal--shown');
    });

    const cancelConfirm = document.querySelector('#cancelmodal .cancel-modal__confirm');
    cancelConfirm && (cancelConfirm.onclick = function(e) {
        e.preventDefault();
        window.location = '/';
    });

    const cancelCancel = document.querySelector('#cancelmodal .cancel-modal__cancel');
    cancelCancel && (cancelCancel.onclick = function(e) {
        e.preventDefault();
        const modal = document.getElementById('cancelmodal');
        modal.classList.remove('cancel-modal--shown');
        form.classList.remove('form--blured');
    });

    const paymentMethodRadios = document.querySelectorAll('input[name=PaymentMethod]');
    const voucherValueInput = document.querySelector('input[name=VoucherValue]');
    for(const paymentMethodRadio of paymentMethodRadios) {
        paymentMethodRadio.onchange = function(e) {
            if(!voucherValueInput || !voucherValueInput.parentElement || !voucherValueInput.parentElement.parentElement) {
                return;
            }
            const inputWrapper = voucherValueInput.parentElement.parentElement;
    
            if(e.target.getAttribute('data-voucher-number-required') && e.target.checked) {
                voucherValueInput.setAttribute('data-required', 'true');
                voucherValueInput.value = null;
                inputWrapper.classList.remove('input-wrapper--hidden');
            } else {
                voucherValueInput.removeAttribute('data-required');
                voucherValueInput.value = 'NotRequired';
                inputWrapper.classList.add('input-wrapper--hidden');
            }
        };
    }
}
