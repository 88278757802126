import { getHiddenElementHeight } from './DOM';

window.collapseElementHeight = (element, hiddenClassModifier) => {
    const sectionHeight = element.scrollHeight;
    element.style.height = sectionHeight + 'px';

    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            element.style.height = null;
        });
    });

    if(hiddenClassModifier) {
        element.classList.add(hiddenClassModifier);
    }
}

window.expandElementHeight = (element, hiddenClassModifier) => {
    const sectionHeight = getHiddenElementHeight(element);

    element.style.height = sectionHeight + 'px';

    element.addEventListener('transitionend', function transitionListener(e) {
        element.removeEventListener('transitionend', transitionListener);
        
        element.style.height = null;
    });

    if(hiddenClassModifier) {
        element.classList.remove(hiddenClassModifier);
    }
}

window.toggleElementHeight = (element, hiddenClassModifier) => {
    const isCollapsed = element.classList.contains(hiddenClassModifier);

    if(isCollapsed) {
        expandElementHeight(element, hiddenClassModifier);
    } else {
        collapseElementHeight(element, hiddenClassModifier);
    }
}