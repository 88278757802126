const Correct = () => ({
  isCorrect: true
});

const InCorrent = (errorMessage) => ({
  isCorrect: false,
  message: String(errorMessage)
});

const validators = {
    text: function() {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const errorForInput = document.querySelector(`.error-message[data-for='${this.name}']`);
      if(!this.value) {
        return InCorrent(errorForInput ? errorForInput.getAttribute('data-required-message') : '');
      }
      if(this.getAttribute('data-type') === 'email') {
        if(!emailRegex.test(this.value)) {
          return InCorrent(errorForInput ? errorForInput.getAttribute('data-email-message') : '');
        }
      }
      return Correct();
    },
    phonenumber: function() {
      const phoneRegex = /^[\+ \(\)\d-]{6,20}$/;
      const errorForInput = document.querySelector(`.error-message[data-for='${this.name}']`);
      if(!this.value) {
        return InCorrent(errorForInput ? errorForInput.getAttribute('data-required-message') : '');
      }
      if(!phoneRegex.test(this.value)) {
        return InCorrent(errorForInput ? errorForInput.getAttribute('data-phonenumber-message') : '');
      }
      return Correct();
    },
    radiobutton: function() {
      const errorForInput = document.querySelector(`.error-message[data-for='${this.name}']`);
      const buttons = document.querySelectorAll(`.radiobutton:not(.radiobutton--hidden) input[data-type='radiobutton'][name='${this.name}']`);
      let anyVisibleChecked = false;
      for(var button of buttons) {
        if(button.checked) {
          anyVisibleChecked = true;
        }
      }
      if(!anyVisibleChecked) {
        return InCorrent(errorForInput ? errorForInput.getAttribute('data-required-message') : '');
      }
      return Correct();
    },
    checkbox: function() {
      const errorForInput = document.querySelector(`.error-message[data-for='${this.name}']`);
      if(!this.checked) {
        return InCorrent(errorForInput ? errorForInput.getAttribute('data-required-message') : '');
      }
      return Correct();
    }
  };

  validators.email = validators['text'];

export default validators;