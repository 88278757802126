window.toggleTooltip = function toggleTooltip(id, tooltipBoxHiddenClass) {
    const chevronClass = 'basic-info__card__chevron';
    const chevronIconInactiveClass = 'basic-info__card__chevron--state-inactive';

    const tooltipOpenIcon = 'ic-question-circle';
    const tooltipCloseIcon = 'ic-times-circle';

    const tooltipBoxes = document.getElementsByClassName(`box-${id}`);
    if(tooltipBoxes) {
        for(const tooltipBox of tooltipBoxes) {
            window.toggleElementHeight(tooltipBox, tooltipBoxHiddenClass);
        }
    }

    const icon = document.getElementById(`icon-${id}`);
    if(icon){
        if(icon.classList.contains(tooltipOpenIcon)) {
            icon.classList.remove(tooltipOpenIcon);
            icon.classList.add(tooltipCloseIcon);
        } else if (icon.classList.contains(tooltipCloseIcon)) {
            icon.classList.remove(tooltipCloseIcon);
            icon.classList.add(tooltipOpenIcon);
        }

        if(icon.classList.contains(chevronClass)) {
            icon.classList.toggle(chevronIconInactiveClass);
        }
    }
};