

const allowedPageTypes = ['SearchPage'];
const pageType = document.getElementById('page-type');
if (pageType && allowedPageTypes.includes(pageType.value)) {
  window.onload = function shrinkAllCourseCardTitles() {
    let searchResults = document.getElementsByClassName('search-result__list__item');
    for (const searchResult of searchResults) {
      searchResult.style.height = '20rem';
    }

    let titles = document.getElementsByClassName('content-container__title');
    let texts = document.getElementsByClassName('content-container__text');

    for (let i=0; i< titles.length; i++) {
      var title = titles[i];
      var text = texts[i];

      const initialTitleHeightValue = parseInt(window.getComputedStyle(title, null).getPropertyValue('height'));
      const initialTextHeightValue = parseInt(window.getComputedStyle(text, null).getPropertyValue('height'));
      
      text.style.textOverflow = "ellipsis";
      text.style.overflow = "hidden";
      text.style.cssText += "display: -webkit-box;"
      text.style.cssText += "-webkit-box-orient: vertical;"
      text.style.cssText += "-webkit-line-clamp: 2;"

      if(initialTitleHeightValue * 2 < initialTextHeightValue || initialTitleHeightValue > initialTextHeightValue)
      {
        text.style.cssText = text.style.cssText.replace("-webkit-line-clamp: 2;", "-webkit-line-clamp: 1;");
      } else {
        text.style.cssText = text.style.cssText.replace("-webkit-line-clamp: 1;", "-webkit-line-clamp: 1;")
      }
    }
  };

  window.onresize = function changeText() {
    let titles = document.getElementsByClassName('content-container__title');
    let texts = document.getElementsByClassName('content-container__text');

    for (let i=0; i< titles.length; i++) {
      var title = titles[i];
      var text = texts[i];

      const initialTitleHeightValue = parseInt(window.getComputedStyle(title, null).getPropertyValue('height'));
      const initialTextHeightValue = parseInt(window.getComputedStyle(text, null).getPropertyValue('height'));
      
      text.style.textOverflow = "ellipsis";
      text.style.overflow = "hidden";
      text.style.cssText += "display: -webkit-box;"
      text.style.cssText += "-webkit-box-orient: vertical;"

      if(initialTitleHeightValue * 2 < initialTextHeightValue || initialTitleHeightValue > initialTextHeightValue)
      {
        text.style.cssText = text.style.cssText.replace("-webkit-line-clamp: 2;", "-webkit-line-clamp: 1;");
      } else {
        text.style.cssText = text.style.cssText.replace("-webkit-line-clamp: 1;", "-webkit-line-clamp: 1;")
      }
    }
  }
}
