import { httpStatus } from './enums/http-status.enum';
import Validator from './common/formValidator';

const showMessage = (success) => {
    const newsletterMessageVisibleModifier = 'newsletter__message--visible';
    const newsletterMessages = Array.from(document.getElementsByClassName('newsletter__message'));
    
    if(newsletterMessages) {
        newsletterMessages.forEach(newsletterMessage => {
            newsletterMessage.classList.remove(newsletterMessageVisibleModifier);
        });

        const newsletterMessagesToDisplay =  newsletterMessages
            .filter(x => x.classList.contains(success 
                ? 'newsletter__message--success'
                : 'newsletter__message--fail'));

        newsletterMessagesToDisplay.forEach(successMessage => {
            successMessage.classList.add(newsletterMessageVisibleModifier);
        });
    }
}

const newsletterForm = document.getElementById('newsletter-form');

const addUserToNewsletter = async (data) => {
    try {
        const response = await fetch('/api/v1/newsletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
        const ok = response.status === httpStatus.OK
        showMessage(ok);
        if(ok) {
            newsletterForm.onUserAddedSuccessfully && newsletterForm.onUserAddedSuccessfully();
        }
    } catch(err) {
        showMessage(false);
    }
}

const newsletterButton = document.getElementById('newsletter-button');
newsletterButton && (newsletterButton.onclick = function newsletterButtonClicked(e) {
    const emailInput = document.getElementById('newsletter-email');
    const newsletterCheckbox = document.getElementById('newsletter-checkbox');
    if(emailInput && newsletterCheckbox.checked) {
        const data = ['email','__RequestVerificationToken'].reduce((formData,element) => {
            formData[element] = newsletterForm.elements[element].value;
            return formData;
        }, {});
        addUserToNewsletter(data);
        newsletterCheckbox.checked = false;
        newsletterButton.disabled = true;
        emailInput.value = '';
        e.preventDefault();
    }
});


if(newsletterForm) {
    const validator = new Validator('newsletter-form');
    validator.initialize();

    const email = document.getElementById('newsletter-email');
    const checkbox = document.getElementById('newsletter-checkbox');

    email && (email.oninput = () => {
        validator.validateAndEnableSubmitButton();
    });
    checkbox && (checkbox.onchange = () => {
        validator.validateAndEnableSubmitButton();
    });

    validator.validateAndEnableSubmitButton();
};