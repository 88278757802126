const videoplayers = Array.from(document.querySelectorAll('.video-player'));
import VimeoPlayer from '@vimeo/player';

if (videoplayers && videoplayers.length > 0) {
  const addVideoToDatalayer = title => {
    if (!window.dataLayer.find(x => x['videoTitle'] === String(title))) {
      window.dataLayer.push({
        videoTitle: title,
        event: 'interactionsVideoPlay'
      });
    }
  };
  const loadYouTubePlayers = players => {
    if (!window.onYouTubeIframeAPIReady) {
      window.onYouTubeIframeAPIReady = () =>
        players.forEach(({ videoUrl, innerWrapper }) => {
          const youtubeVideoIdRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
          const videoId = youtubeVideoIdRegex.exec(videoUrl)[1];
          const elementId = `youtube${videoUrl}player`;
          innerWrapper.innerHTML = `<div id="${elementId}"></div>`;

          const ytVideoPlayer = new YT.Player(elementId, {
            height: '390',
            width: '640',
            videoId: videoId,
            events: {
              onStateChange: event => {
                if (event.data == YT.PlayerState.PLAYING) {
                  const { title } = ytVideoPlayer.getVideoData();
                  addVideoToDatalayer(title);
                }
              }
            }
          });
        });
    }
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  };

  const loadVimeoPlayers = async players =>
    players.forEach(({ videoUrl, innerWrapper }) => {
      const elementId = `vimeo${videoUrl}player`;
      const containerForVimeo = `<div data-vimeo-url="${videoUrl}" data-vimeo-responsive="true" data-vimeo-title="true" id="${elementId}"></div>`;
      innerWrapper.innerHTML = containerForVimeo;
      const vimeoPlayer = new VimeoPlayer(elementId);
      vimeoPlayer.on('play', async () => {
        const videoTitle = await vimeoPlayer.getVideoTitle();
        addVideoToDatalayer(videoTitle);
      });
    });

  const videoPlayerDOMLoaders = {
    YouTube: loadYouTubePlayers,
    Vimeo: loadVimeoPlayers
  };

  Object.entries(
    videoplayers.reduce((reducer, containerForPlayer) => {
      const playerSourceType = containerForPlayer.getAttribute('data-video-source-type');
      const videoUrl = containerForPlayer.getAttribute('data-url');
      const innerWrapper = containerForPlayer.querySelector('.video-player__inner-wrapper');
      reducer[playerSourceType] = [
        ...(reducer[playerSourceType] || []),
        {
          innerWrapper,
          videoUrl
        }
      ];
      return reducer;
    }, {})
  ).forEach(([source, playersToLoad]) => {
    videoPlayerDOMLoaders[source] && videoPlayerDOMLoaders[source](playersToLoad);
  });
}
