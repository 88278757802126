window.shrinkCourseCardTitle = function shrinkCourseCardTitle(title) {
  if (!title) {
    return;
  }

  const fontSizeThreshold = 10;

  const initialFontSizeValue = window.getComputedStyle(title, null).getPropertyValue('font-size');
  let currentFontSize = parseInt(initialFontSizeValue);

  while (title.scrollHeight > title.offsetHeight) {
    currentFontSize--;
    title.style.fontSize = currentFontSize + 'px';

    if (currentFontSize === fontSizeThreshold) {
      break;
    }
  }
};

const allowedPageTypes = ['CourseBookingPage', 'CourseDetailsPage', 'CourseSearchPage', 'ArticlePage'];
const pageType = document.getElementById('page-type');
if (pageType && allowedPageTypes.includes(pageType.value)) {
  window.onload = function shrinkAllCourseCardTitles() {
    const titles = document.getElementsByClassName('course-card__info__title');

    for (const title of titles) {
      window.shrinkCourseCardTitle(title);
    }
  };
}
