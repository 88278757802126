var elementsToFadeInBottom = Array.from(document.querySelectorAll(".teaser__inner--fade-in-bottom"));

var config = {
    threshold: 0.13
};

if (elementsToFadeInBottom.length > 0) {
    var observer = new IntersectionObserver(onIntersection, config);
    elementsToFadeInBottom.forEach(function(elem) {
        observer.observe(elem);
    });
};

function onIntersection(entries, observer) {
    entries.forEach(function(entry) {
        if (entry.isIntersecting) {
            var entryTarget = entry.target;
            entryTarget.classList.remove("teaser__inner--fade-in-bottom");
            observer.unobserve(entryTarget);
        }
    });
};