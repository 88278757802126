import Validator from './common/formValidator';
import Datepickk from 'datepickk';
import dateService from '../react/services/dateService';

if (
  document.getElementById('page-type') &&
  document.getElementById('page-type').value === 'CertificateBookingPage' &&
  document.getElementById('bookingForm')
) {
  const radioButtonHtmlForParams = (value, checked, display) => {
    return `<span class="radiobutton radiobutton--hidden">
        <input class="radiobutton__input"
               type="radio"
               name="Time"
               value="${value}"
               ${checked ? 'checked' : ''} />
        <label class="radiobutton__label">${display}</label>
        </span>`;
  };

  const updateDisabledDates = (datepickerInstance, input) => {
    let disabledDays = input
      .getAttribute('data-disabled-days')
      .split(',')
      .map(x => +x);
    const excludedDates = JSON.parse(input.getAttribute('data-excluded-days')).dates;
    datepickerInstance.disabledDays = null;
    datepickerInstance.disabledDays = disabledDays;

    if (excludedDates && excludedDates.length > 0) {
      datepickerInstance.disabledDates = null;
      datepickerInstance.disabledDates = excludedDates.map(x => new Date(x));
    }
  };
  const createChangeEvent = () => {
    let event = document.createEvent('HTMLEvents');
    event.initEvent('change', false, true);
    return event;
  };
  const getAvailableTimesForCity = (input, dateInputInstance) => {
    const timesForDays = JSON.parse(input.getAttribute('data-day-times'));

    return [...timesForDays];
  };
  const onTimeRadioChange = e => {
    const that = e.target;
    if (that.parentElement.classList.contains('radiobutton--hidden')) {
      e.preventDefault();
      return;
    }
    timeInput.value = that.value;
    timeInput.dispatchEvent(createChangeEvent());
  };
  const valueForTime = (time, addSeconds) =>
    `${('0' + time.hour).slice(-2)}:${('0' + time.minute).slice(-2)}${addSeconds ? ':00' : ''}`;
  const updateTimesForDay = (
    containerForTimesInstance,
    availableTimesForCity,
    day,
    dateInputInstance,
    excludedDates
  ) => {
    const timesForDay = availableTimesForCity.find(x => x.day === day).times;
    const radiobuttons = Array.from(containerForTimesInstance.querySelectorAll('input[type="radio"]'));
    const mapped = timesForDay.map(x => valueForTime(x, true));
    const selectedDate = dateService.parseDate(dateInputInstance.value);
    const disabledDates = excludedDates.map(x => dateService.parseDate(x));
    const foundExcludedDates = disabledDates.filter(
      x => x.year == selectedDate.year && x.month == selectedDate.month && x.day == selectedDate.day
    );
    const excludedDateForTime = mapped => foundExcludedDates.find(x => valueForTime(x, true) === mapped);
    radiobuttons.forEach(radio => {
      const map = mapped.find(x => x === radio.value);
      if (map && !excludedDateForTime(map)) {
        radio.parentElement.classList.remove('radiobutton--hidden');
      } else {
        if (!radio.parentElement.classList.contains('radiobutton--hidden')) {
          radio.parentElement.classList.add('radiobutton--hidden');
        }
      }
    });
  };
  const disableAllTimes = containerForTimesInstance => {
    for (let radio of Array.from(containerForTimesInstance.querySelectorAll('input[type="radio"]'))) {
      if (!radio.parentElement.classList.contains('radiobutton--hidden')) {
        radio.parentElement.classList.add('radiobutton--hidden');
      }
      radio.checked = false;
    }
  };
  const unlockDatePickerIfLocked = (datepickerInstance, containerForCalendarInstance) => {
    if (datepickerInstance.locked) {
      datepickerInstance.locked = false;
      containerForCalendarInstance.classList.remove('calendar--hidden');
    }
  };
  const formatDate = date =>
    new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    ).toISOString();

  const validator = new Validator('bookingForm', true);
  const datePicker = new Datepickk();
  const dateInput = document.getElementById('dateInput');
  const timeInput = document.getElementById('timeInput');
  const containerForTimes = document.querySelector('#bookingForm .time .time__wrapper');
  const containerForCalendar = document.querySelector('.section--general .calendar');
  const cities = document.querySelectorAll('#bookingForm .city .radiobutton input');
  const allTimes = JSON.parse(timeInput.getAttribute('data-all-times'));
  let availableTimes = [];
  let disabledDates = [];
  const datePickerSettings = {
    inline: true,
    fullscreen: false,
    locked: true,
    range: false,
    maxSelections: 1,
    minDate: new Date(),
    lang: 'se',
    container: containerForCalendar,
    onSelect: function(checked) {
      timeInput.value = '';
      const day = this.getDay();
      if (!checked) {
        dateInput.value = '';
        disableAllTimes(containerForTimes);
      } else {
        dateInput.value = formatDate(this);
        updateTimesForDay(containerForTimes, availableTimes, day, dateInput, disabledDates);
      }
      dateInput.dispatchEvent(createChangeEvent());
    }
  };

  validator.initialize();

  if (allTimes && allTimes.length > 0) {
    let html = '';
    allTimes.forEach(time => {
      const value = valueForTime(time, true);
      const display = valueForTime(time);
      html += radioButtonHtmlForParams(value, false, display);
    });
    containerForTimes.innerHTML = html;
    containerForTimes.querySelectorAll('input[type="radio"]').forEach(x => {
      x.addEventListener('change', onTimeRadioChange);
    });
  }

  for (let cityButton of cities) {
    cityButton.addEventListener('change', e => {
      unlockDatePickerIfLocked(datePicker, containerForCalendar);
      datePicker.unselectAll();
      availableTimes = getAvailableTimesForCity(e.target);
      disabledDates = JSON.parse(e.target.getAttribute('data-partially-excluded-days')).dates;
      updateDisabledDates(datePicker, e.target);
    });
  }

  for (let setting in datePickerSettings) {
    datePicker[setting] = datePickerSettings[setting];
  }

  datePicker.show();

  for (let cityButton of cities) {
    if (cityButton.defaultChecked) {
      availableTimes = getAvailableTimesForCity(cityButton);
      disabledDates = JSON.parse(e.target.getAttribute('data-partially-excluded-days')).dates;
      updateDisabledDates(datePicker, cityButton);
      if (dateInput.value) {
        unlockDatePickerIfLocked(datePicker, containerForCalendar);
        const parsedDate = Date.parse(dateInput.value);
        datePicker.selectDate(parsedDate, false);
      }
    }
  }
}
